/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var SoftwareDesign = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                // Parallax Scroll / BG in FF
                //if (!jQuery.browser.mozilla) {
                    jQuery('[data-parallax-parallax="scroll"]').parallax();
                /*} else {
                    jQuery('[data-parallax-parallax="scroll"]').each(function() {
                        var dataImageSrc = jQuery(this).attr('data-image-src');
                        jQuery(this).css('background-image', 'url('+dataImageSrc+')');
                    });
                }*/
                //Custom checkbox
                $('input[type="checkbox"]').each(function() {
                    if($(this).parent().hasClass("custom-checkbox") === false) {
                        $(this).wrap('<span class="custom-checkbox"></span>');
                        console.log("loaded");
                    }
                });

                var checkBox = $('.custom-checkbox input[type="checkbox"]');
                if (checkBox.length) {
                    checkBox.each(function () {
                        if ($(this).is(':checked')) {
                            $(this).parent().addClass("selected");
                            console.log("nope");
                        }
                    });
                   $(checkBox).click(function () {
                       $(this).parent().toggleClass("selected");
                       console.log("add check");
                   });
                }


                //Smooth Scrolling functions
                //jQuery to collapse the navbar on scroll
                window.onscroll = function(){
                    if (document.body.scrollTop > 50) {
                        jQuery(".navbar-fixed-top").addClass("top-nav-collapse");
                    } else {
                        jQuery(".navbar-fixed-top").removeClass("top-nav-collapse");
                    }
                };

                //jQuery for page scrolling feature - requires jQuery Easing plugin
                jQuery(function() {
                    jQuery('a.page-scroll').bind('click', function(event) {
                        var jQueryanchor = jQuery(this);
                        jQuery('html, body').stop().animate({
                            scrollTop: jQuery(jQueryanchor.attr('href')).offset().top
                        }, 1500, 'easeInOutExpo');
                        event.preventDefault();
                    });
                });

                //Custom radio
                $('input[type="radio"]').each(function() {
                    if($(this).parent().hasClass("custom-radio-btn") === false) {
                        $(this).wrap('<span class="custom-radio-btn"></span>');
                    }
                });

                var radio = $('.custom-radio-btn input[type="radio"]');
                $(radio).each(function () {
                    if ($(this).is(':checked')) {
                        $(this).parent().addClass("selected");
                    }
                });

                $(radio).click(function () {
                    var groupName = $(this).attr('name');
                    $('.custom-radio-btn input[type="radio"][name="' + groupName + '"]').each(function(index){
                        $(this).parent().removeClass("selected");
                        $(this).parent().prop("checked", false);
                    });
                    $(this).parent().addClass("selected");
                    $(this).parent().prop("checked", true);
                });

                // Custom Select
                $(".custom-select").select2({
                    minimumResultsForSearch: 9999999,
                    width: '100%',
                    dropdownAutoWidth : true
                });

                function initFocusEvents(selector) {
                    $(document).on('focus', selector, function()
                    {
                        var $this = $(this);
                        if ($this.next('.label-rest').length !== 0) {
                            $(this).next('.label-rest').removeClass('label-rest').addClass('label-hover');
                        } else {
                            $(this).parent().siblings('.label-rest').removeClass('label-rest').addClass('label-hover');
                        }
                    });

                    $(document).on('focusout', selector, function()
                    {
                        var $this = $(this);
                        var $labels = $this.next('.label-hover');

                        if ($labels.length === 0) {
                            $labels = $this.parent().siblings('.label-hover');
                        }

                        if (!$this.val()) {
                            $labels.removeClass('label-hover').addClass('label-rest');
                        }
                    });

                    var hideShowLabel = function(e)
                    {
                        if ($(this).val().length > 0)
                        {
                            $(this).next('label').css('visibility', 'hidden');
                        }

                        else
                        {
                            $(this).next('label').css('visibility', 'visible');
                        }
                    };

                    $(document).on('keyup', selector, function(e)
                    {
                        hideShowLabel.call(this, e);
                    });
                }

                initFocusEvents('.style-input');

                /*$(window).load(function()
                {
                    equalHeight('.row-equal-watch .col-equal');
                });*/
                $(window).load(function() {
                    equalHeight('.row-equal-watch .col-equal');
                });
                equalHeight('.row-equal-watch .col-equal');

                $(window).resize(function()
                {
                    equalHeight('.row-equal-watch .col-equal');
                });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'page_template_default': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };
    

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = SoftwareDesign;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);





})(jQuery); // Fully reference jQuery after this point.


// Global Utils & Functions
function findBootstrapEnvironment()
{
    var envs = ['xs', 'sm', 'md', 'lg'];

    var $el = jQuery('<div>');
    $el.appendTo(jQuery('body'));

    for (var i = envs.length - 1; i >= 0; i--) {
        var env = envs[i];

        $el.addClass('hidden-'+env);
        if ($el.is(':hidden')) {
            $el.remove();
            return env;
        }
    }
}



function callAjax(action, call, variables, method)
{
    if (typeof(variables) === "undefined")
    {
        variables = new Object();
    }

    if (typeof(method) === "undefined")
    {
        method = "POST";
    }

    var form_data = variables;

    form_data['action'] = action;

    jQuery.ajax(
    {
        type: method,
        url: ajaxurl,
        data: form_data,
        success: function(data, textStatus, XMLHttpRequest)
        {
            call(data);
        },
        error: function(MLHttpRequest, textStatus, errorThrown)
        {

        }
    });
}

var ajaxLoading = false;

function ajaxLoader(div)
{
    if (ajaxLoading)
    {
        jQuery("#ajax-spinner").remove();

        ajaxLoading = false;

        return;
    }

    ajaxLoading = true;

    if (typeof(div) === "undefined")
    {
        div = "body";
    }

    jQuery(div).before('<div id="ajax-spinner"></div>');
}

function equalHeight(container) {
    var currentTallest = 0,
        currentRowStart = 0,
        rowDivs = new Array(),
        $el,
        topPosition = 0;

    jQuery(container).each(function () {
        $el = jQuery(this);
        jQuery($el).height('auto');
        topPosition = $el.position().top;

        if (currentRowStart != topPosition) {
            for (var currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }

            rowDivs.length = 0; // empty the array
            currentRowStart = topPosition;
            currentTallest = $el.height();
            rowDivs.push($el);
        }

        else {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
        }

        for (var currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
        }
    });
}

    function getViewportSize(w) {

        // Use the specified window or the current window if no argument
        w = w || window;

        // This works for all browsers except IE8 and before
        if (w.innerWidth != null) return { w: w.innerWidth, h: w.innerHeight };

        // For IE (or any browser) in Standards mode
        var d = w.document;
        if (document.compatMode == "CSS1Compat")
            return { w: d.documentElement.clientWidth,
                h: d.documentElement.clientHeight };

        // For browsers in Quirks mode
        return { w: d.body.clientWidth, h: d.body.clientHeight };

    }

    jQuery("#menu-main-menu").click(function(event){
        var item = jQuery(event.target);
        if(getViewportSize().w > 768) {
           if( item.attr("href") !== undefined) {
                window.location.replace(item.attr("href"));
           }
        }
    });


    jQuery(document).ready(function() {
        ScrollRevealInit();
    });

    function ScrollRevealInit() {
        window.sr = ScrollReveal();

        sr.reveal('.component-two .content', {duration: 1000, delay: 500});
        sr.reveal('.component-three .right-holder', {origin: 'bottom', distance: "0px", duration: 1000, delay: 500});
        sr.reveal('.component-four .block', {distance: "0px", duration: 1000, delay: 500}, 200);
        //cmp 5 left block
        sr.reveal('.component-five .green-background .component-holder', {
            origin:'bottom',
            distance: "0px",
            duration: 1000,
            delay: 500
        });
        //cmp 5 right block
        sr.reveal('.component-five .right-background .component-holder', {
            distance: "0px",
            duration: 1000,
            delay: 500
        });
        //cmp 6
        sr.reveal('.component-six .news-block-anim', {distance: "0px", duration: 1000, delay: 500}, 200);
        //cmp 7
        sr.reveal('.component-seven .content', {duration: 1000, delay: 500});
        //cmp 8
        sr.reveal('.component-eight .person-holder', {duration: 1000, delay: 500}, 200);
        //CMP 9
        sr.reveal('.component-nine .info-block', {duration: 1000, delay: 500}, 200);
        //cmp 12
        //sr.reveal('.component-twelve .cp12-step', {duration: 1000, delay: 500}, 200);
        //cp 13
        sr.reveal('.component-thirteen i', {distance: "20px", duration: 1000, delay: 500}, 200);
        //cmp 14
        sr.reveal('.component-fourteen h3', {distance: "20px", duration: 1000, delay: 500});
        //cmp 15
        sr.reveal('.component-sixteen h3', {distance: "0px", duration: 1000, delay: 500}, 200);
        //16
        sr.reveal('.component-sixteen h3', {distance: "0px", duration: 1000, delay: 500}, 200);
        //cmp 18
        sr.reveal('.component-eighteen .title-holder, .component-eighteen hr', {
            origin: 'top',
            distance: "40px",
            duration: 1000,
            delay: 500
        }, 200);
        //component 19
        sr.reveal('.component-nineteen .location-holder', {distance: "0px", duration: 1000, delay: 500, afterReveal: function(){equalHeight('.row-equal-watch .col-equal');} }, 200);
        //component 21
        sr.reveal('.component-twenty-one .news-block', {distance: "0px", duration: 1000, delay: 500}, 200);
        //cmp 22
        sr.reveal('.component-twenty-two .right-section', {
            origin: 'bottom',
            distance: "0px",
            duration: 500,
            delay: 500
        });
        //cmp 23
        sr.reveal('.component-twenty-three li', {origin: 'left', distance: "100px", duration: 1000, delay: 500}, 200);
        //cmp 26
        sr.reveal('.component-twenty-six .faq-section', {
            origin: 'bottom',
            distance: "0px",
            duration: 1000,
            delay: 500
        });
        sr.reveal('.component-twenty-six .application-section', {
            origin: 'bottom',
            distance: "0px",
            duration: 1000,
            delay: 500
        });
        //cmp 30
        sr.reveal('.component-thirty .left-div .question', {distance: "0px", duration: 1000, delay: 500}, 200);
        sr.reveal('.component-thirty .right-div .question', {distance: "0px", duration: 1000, delay: 500}, 200);
        //cmp 31
        sr.reveal('.component-thirty-one .component-block', {distance: "0px", duration: 1000, delay: 500}, 200);
        //cmp 33
        sr.reveal('.component-thirty-three .bullet-item', {
            origin: "left",
            distance: "100px",
            duration: 1000,
            delay: 500
        }, 200);
        //footer
        sr.reveal('.mailchimp-container h2,.mailchimp-container form ', {
            origin: "bottom",
            distance: "0px",
            duration: 1000,
            delay: 500
        });
    }

Number.prototype.formatMoney = function(c, d, t){
    var n = this,
        c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};


// Code for calculator
jQuery('a[href="#calc"]').click(function(event) {
    event.stopPropagation();
    event.preventDefault();

    // if the calculator has already been created show it
    if (jQuery('.calc-model .component-one').length){
        jQuery('.calc-model').fadeIn();
        jQuery('body').css('overflow', 'hidden');
        return
    }

    // of there is already an instance of the calculator on the page scroll to it.
    if(jQuery('.component-one').length){
        jQuery("html, body").delay(500).animate({
            scrollTop: jQuery('.component-one').offset().top
        }, 2000);
        return
    }

    //create an empty div in the footer
    jQuery('footer').append('<div class="calc-model"><div class="close-m"></div> </div>');

    // enable spinner
    jQuery('.calc-model').append('<div class="ajax"> &nbsp;</div>');

    //lock scroll
    jQuery('body').css('overflow', 'hidden');

    // add event listener for close button created in empty div
    jQuery('.calc-model .close-m').click(function(){
        jQuery('.calc-model').fadeOut();
        jQuery('body').css('overflow', 'scroll');
    });


    // call the calc
    var calcData = { 'action' : 'get_calc' };
    jQuery.ajax(
        {
            type: "POST",
            url: ajaxurl,
            data: calcData,
            success: function(data, textStatus, XMLHttpRequest)
            {
                // sucessful call, but backend error
                if( data.errors && data.errors.length){
                    alert(data.errors);
                    return
                };
                // append the calc to the footer div
                jQuery('.calc-model').append(data);
                // disable the spinner
                jQuery('.ajax').hide();
            },
            error: function(MLHttpRequest, textStatus, errorThrown)
            {
                // hide spinner
                jQuery('.ajax').hide();
                // hide overlay
                jQuery('.calc-model').fadeOut();
                //unlock body
                jQuery('body').css('overflow', 'scroll');
                // trigger basic error message
                jQuery('body').append("<div class='error-message' style='display:none'><h3>Something went wrong with the calculator, please refresh and try again</h3></div>");
                // fade in error
                jQuery('.error-message').fadeIn();
                // fade out the error in 5 seconds
                setTimeout(function(){
                    jQuery('.error-message').fadeOut();
                },5000);
            }
        });

});